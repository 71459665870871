<template>
    <div class="main">
<!--        <div class="headerVideo">-->
<!--            <img src="@/assets/logoXY.png">-->
<!--        </div>-->
        <div class="divText2" style="margin-top:0">
            <div>
                <img src="@/assets/pic/01-img.png" style="border-radius: 4px;">
            </div>
        </div>

<!--        直播-->

        <div class="divText2">
            <div class="mzbInde" @click="goHref('/VideoMain')" style="border-radius:0px; max-height:160px;">
                <img :src="mzbImg" style="border-radius:0px;">
                <div style="position:absolute; text-align: center; top:50%; left:50%; transform: translate(-50%,-50%); width:100%;" >
                    <h1 style="font-weight: bold;  text-align: center">{{mzbArry}}</h1>
                    <span class="TyBanner">点击立享更多云赏>></span>
                </div>
            </div>
            <div>
<!--                <video-player class="video-player vjs-custom-skin" :options="options" />-->
<!--                <div class="textDiv" style="margin-top:10px;">-->
<!--                    <div>-->
<!--                        <h1 style="font-size:24px; font-weight:bold;"><span style="color:#000000">此刻洛阳</span><span class="spanNew">直播中</span></h1>-->
<!--                        <p style="font-size:12px; margin-top:7px; color:#878787;">精挑细选此刻美景，一部手机看尽最美洛阳</p>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
                <div class="fourDiv">
                    <div class="chilrden"  v-for="(goods,index) in mzb" :key="index" @click="goHref2('Video',goods.id)">
                            <img :src="goods.img">
                            <div class="Child-bootom">
                                <p style="color:#ffffff">{{goods.title}}</p>
                            </div>
                    </div>
                </div>
        </div>

<!--        赏花季-->
        <div class="divText2">
            <div style="color:#000000" class="font-header">
                <h1 style="font-size:1.4rem">最美赏花季</h1>
                <p style="width:20%; margin:0; color:#878787;" class="pRight"><a :href="zmshjMore">更多></a></p>
            </div>
<!--            最美赏花季需要加链接地址-->
            <div class="mzbInde" style="border-radius:0; max-height:160px;">
                <a :href="zmshjUrl">
                    <div class="spanIcon">
                        优选线路
                    </div>
                    <img :src="zmshjImg" style="border-radius:0;">
                    <div class="Child-bootom">
                        <p style="font-size:1rem; color:#ffffff">{{zmshjTop.title}}</p>
                    </div>
                </a>

            </div>
            <div class="fourDiv">
                <div class="chilrden"  v-for="(goods,index) in zmshj" :key="index">
                    <a :href="goods.url">
                        <img :src="goods.img">
                        <div class="Child-bootom">
                            <p style="color:#ffffff;overflow:hidden; text-overflow:ellipsis; white-space:nowrap;">{{goods.title}}</p>
                        </div>
                    </a>

                </div>
            </div>

        </div>

<!--     最新花讯   -->

        <div class="divText2">
            <div style="color:#000000" class="font-header">
                <h1 style="font-size:1.4rem">最新花讯</h1>
                <p style="width:20%; margin:0; color:#878787;" class="pRight"><a :href="zxhxMore">更多></a></p>
            </div>
            <a :href="zxhxUrl">
                <div class="mzbInde"  style="border-radius:0; max-height:160px;">
                    <img :src="zxhxImg" style="border-radius:0;">

                    <div class="Child-bootom" style="background: white; color:#000000">
                        <p style="font-size:1rem;">{{zxhxTop.title}}</p>
                    </div>
                </div>
            </a>


            <div class="fourDiv">
                <div class="chilrden"  v-for="(goods,index) in zxhx" :key="index">
                    <a :href="goods.url">
                        <img :src="goods.img">
                        <div class="Child-bootom" style="background: white; color:#000000">
                            <p style="overflow:hidden; text-overflow:ellipsis; white-space:nowrap;" >{{goods.title}}</p>
                        </div>
                    </a>

                </div>
            </div>

        </div>

        <!--        花的味道-->

        <div class="divText2">
            <div style="color:#000000" class="font-header">
                <h1 style="font-size:1.4rem">花的味道</h1>
                <p style="width:20%; margin:0; color:#878787;" class="pRight"><a :href="hdwdMore" >更多></a></p>
            </div>
            <a :href="hdwdUrl">
                <div class="mzbInde"  style="border-radius:0; max-height:160px;">
                    <img :src="hdwdImg" style="border-radius:0;">
                    <div class="spanIcon">
                        诚选特产
                    </div>
                    <div class="Child-bootom">
                        <p style="font-size:1rem; color:#ffffff">{{hdwdTop.title}}</p>
                        <p style="font-size:1rem; color:#ffffff; font-weight:600;" >{{hdwdTop.price}}元</p>
                    </div>
                </div>
            </a>

            <div class="fourDiv">
                <div class="chilrden"  v-for="(goods,index) in hdwd" :key="index">
                    <a :href="goods.url">
                        <img :src="goods.img">
                        <div class="Child-bootom" style="color:#ffffff">
                            <p style="overflow:hidden; text-overflow:ellipsis; white-space:nowrap;" >{{goods.title}}</p>
                            <p style="overflow:hidden; text-overflow:ellipsis; white-space:nowrap; font-size:1rem; width:30%; text-align: right; font-weight:600;" >{{goods.price}}元</p>
                        </div>
                    </a>

                </div>
            </div>
        </div>

<!--        &lt;!&ndash;        赏花圣地&ndash;&gt;-->

<!--        <div class="divText2">-->
<!--            <div style="color:#000000" class="font-header">-->
<!--                <h1 style="font-size:1.4rem">赏花胜地</h1>-->
<!--                <p style="width:20%; margin:0; color:#878787;" class="pRight"><a :href="shsdMore">更多></a></p>-->
<!--            </div>-->
<!--            <div class="shsdImg" v-for="(goods,index) in shsd" :key="index">-->
<!--                <a :href="goods.url">-->
<!--                    <div style="border-radius:3px; max-height:180px; overflow: hidden;">-->
<!--                        <img :src="goods.img" style="display: block; ">-->
<!--                    </div>-->
<!--                    <div style="color:#000000">-->
<!--                        <h1 style="color:#F66C00; font-size:1.4rem; margin-top:14px;">{{goods.title}}</h1>-->
<!--                        <p style="\font-size:1rem; margin-top:10px; color:#a4a2a2">{{goods.about}}</p>-->
<!--                    </div>-->
<!--                </a>-->

<!--            </div>-->

<!--        </div>-->

    </div>

</template>

<script>
    const options = {
        playbackRates: [0.25, 0.5, 1.0, 1.25, 2.0], // 可选择的播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        sources: [{
            // type : "video/mp4",
            type: "application/x-mpegURL",
            src: require('/src/assets/mov1.mp4')
        }],
        poster:require('@/assets/mdg.png'),
        notSupportedMessage: "前方视频信号中断，请稍后再试。"
    };
    var index_djs;
    import axios from 'axios'
    export default {
        name: "index",
        data(){
            return{
                options,
                info:'',
                localTimeStamp: new Date().getTime(),
                daoTime: '',
                mzb:'',
                mzbImg:"",
                zmshjMore:'',
                zxhxMore:'',
                hdwdMore:'',
                shsdMore:'',
                zmshj:'',
                zmshjImg:'',
                zmshjUrl:'',
                zmshjTop:'',
                hdwd:'',
                hdwdImg:'',
                hdwdUrl:'',
                hdwdTop:'',
                shsd:'',
                shsdImg:'',
                shsdTop:'',
                zxhx:'',
                zxhxImg:'',
                zxhxUrl:'',
                zxhxTop:'',
                poster:"../assets/banner.png",
                scroll:0,
                mzbArry:null
            }
        },

        mounted(){
            this.djs();
            clearInterval(index_djs);
            index_djs = setInterval(this.djs, 1000);
            this.dataUrl();
            window.addEventListener('scroll',this.goKeepLive)
            this.getScroll();
        },
        methods:{
            goHref(url){
                this.$router.push(url)
            },

            goKeepLive(){
                this.scroll = document.documentElement.scrollTop||document.body.scrollTop;
                // console.log(this.scroll);
                sessionStorage.setItem('scroll',this.scroll);
                // document.documentElement.scrollTop=400;
            },

            getScroll(){
                console.log(sessionStorage.getItem('scroll'));
                document.documentElement.scrollTop=sessionStorage.getItem('scroll');
            },



            goHref2(title,id){
                this.$router.push({
                    name:title,
                    query:{
                        id:id
                    }
                })
            },

            dataUrl(){
                axios.get('/data/mzb.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.mzb=response.data.list.slice(0,2);
                            console.log(this.mzb);
                            this.mzbImg=this.mzb[0].img;
                            this.mzbArry=response.data.top.title;
                            console.log(response.data.top.hls);
                            this.mzbImg=response.data.top.img;
                            console.log(this.mzbImg);
                        }
                    );

                axios.get('/data/zmshj.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.zmshj=response.data.list;
                            console.log(this.zmshj);
                            this.zmshjMore=response.data.more;
                            console.log(this.zmshjMore);
                            this.zmshjImg=response.data.top.img;
                            this.zmshjTop=response.data.top;
                            this.zmshjUrl=response.data.top.url;
                            console.log(this.zmshjImg);

                            // zmshjMore:'',
                            //     zxhxMore:'',
                            //     hdwdMore:'',
                            //     shsdMore:''
                        }
                    );

                axios.get('/data/zxhx.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.zxhx=response.data.list;
                            this.zxhxMore=response.data.more;
                            console.log(this.zxhx);
                            this.zxhxTop=response.data.top;
                            this.zxhxImg=response.data.top.img;
                            this.zxhxUrl=response.data.top.url;
                            console.log(this.zxhxImg)
                        }
                    );

                axios.get('/data/hdwd.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.hdwd=response.data.list;
                            this.hdwdMore=response.data.more;
                            console.log(this.hdwd);
                            this.hdwdTop=response.data.top;
                            this.hdwdImg=response.data.top.img;
                            this.hdwdUrl=response.data.top.url;
                            console.log(this.hdwdImg)
                        }
                    );

                axios.get('/data/shsd.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.shsd=response.data.list;
                            this.shsdMore=response.data.more;
                            console.log(this.shsd);
                            // this.shsdImg=response.data.top.img;
                            // console.log(response.data.top.img);
                            console.log(this.shsdImg)
                        }
                    );

            },
            countDown(payEndTime, nowTime) {
                let end = new Date(payEndTime).getTime(); //1小时后  60分钟 * 60秒 * 1000  结束时间
                // console.log(end);
                // console.log(nowTime);
                let now;
                nowTime ? now = nowTime : now = new Date().getTime(); //当前时间
                let leftTime = end - now; // 结束秒数 - 现在秒数
                let d, h, m, s;
                if (leftTime > 0) {
                    d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
                    h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
                    m = Math.floor(leftTime / 1000 / 60 % 60);
                    s = Math.floor(leftTime / 1000 % 60);
                    // console.log(d + "天" + h + '小时' + m + '分钟' + s + '秒');
                    let data = {
                        d: d < 10 ? '0' + d : d,
                        h: h < 10 ? '0' + h : h,
                        m: m < 10 ? '0' + m : m,
                        s: s < 10 ? '0' + s : s
                    }
                    return data;
                }
            },
            djs() {
                var vm = this;
                // console.log(vm.localTimeStamp);
                var res = vm.countDown('2022/01/11 10:00:00', vm.localTimeStamp);
                // console.log(res);
                if (res === false) {
                    // vm.daoStatus = false;
                    clearInterval(index_djs);
                } else {
                    // vm.daoStatus = true;
                    vm.daoTime = res;
                }
                vm.localTimeStamp += 1000;
            },

        }
    }
</script>

<style scoped>
    .pRight{
        text-align: right;
    }
    .main{
        width:100vw;
        min-height:100vh;
        /*    background-color: #1C59C1;*/
        /*linear-gradient(to bottom,#558FF1,#09058E)*/
        background: linear-gradient(to bottom, #F9FAFB 0%, #F9FAFB 100%);
    }

    .djs{
        margin:30px 0;
    }

    .textDiv{
        width:100%;
        padding:0px 0 6px 0;
        margin:30px auto 0 auto;
    }

    .headerVideo{
        display:flex;
        width:100%;
        margin:0 auto;
        padding:10px 0;
    }
    .headerVideo img{
        display:block;
        width:50%;
    }

    .video{
        width:100%;
        height:auto;
    }

    .divText{
    }
    .divText .bg{
        position:absolute;
        top:0;
        left:0;
        background-color: #000000;
        opacity: .5;
        z-index:100;
        width:100%;
        height:inherit
    }
    .sapnSize{
        -webkit-text-size-adjust: none;
        font-size:1.2vw
    }

    .p1{
        color: #fff6fc;
        line-height:54px;
        text-align: left;
        padding:30px 20px;
        margin: 0 auto;
        text-indent:2em
    }

    .h1{
        font-size:5vw;
        color:#ffffff;
        font-weight: bold;
    }

    .h2{
        font-size:7.6vw;
        color:#ffffff;
        font-weight: bold;
        margin:20px 0 40px 0;
    }
    /* 禁用iPhone中Safari的字号自动调整 */
    html {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        /* 解决IOS默认滑动很卡的情况 */
        -webkit-overflow-scrolling : touch;
    }

    /* 禁止缩放表单 */
    input[type="submit"], input[type="reset"], input[type="button"], input {
        resize: none;
        border: none;
    }

    /* 取消链接高亮  */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 设置HTML5元素为块 */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }

    /* 图片自适应 */
    img {
        width: 100%;
        height: auto;
        width: auto\9; /* ie8 */
        display: block;
        -ms-interpolation-mode: bicubic;/*为了照顾ie图片缩放失真*/
    }

    /* 初始化 */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        margin: 0;
        padding: 0;
    }
    body {
        font: 12px/1.5 'Microsoft YaHei','宋体', Tahoma, Arial, sans-serif;
        color: #555;
        background-color: #F7F7F7;
    }
    em, i {
        font-style: normal;
    }
    ul,li{
        list-style-type: none;
    }
    strong {
        font-weight: normal;
    }
    .clearfix:after {
        content: "";
        display: block;
        visibility: hidden;
        height: 0;
        clear: both;
    }
    .clearfix {
        zoom: 1;
    }
    a {
        text-decoration: none;
        color: #969696;
        font-family: 'Microsoft YaHei', Tahoma, Arial, sans-serif;
    }
    a:hover {
        text-decoration: none;
    }
    ul, ol {
        list-style: none;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-family: 'Microsoft YaHei';
    }
    img {
        border: none;
    }
    input{
        font-family: 'Microsoft YaHei';
    }
    /*单行溢出*/
    .one-txt-cut{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /*多行溢出 手机端使用*/
    .txt-cut{
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
    }
    /* 移动端点击a链接出现蓝色背景问题解决 */
    a:link,a:active,a:visited,a:hover {
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    .w50{
        width: 50%;
    }
    .w25{
        width: 25%;
    }
    .w20{
        width: 20%;
    }
    .w33{
        width: 33.333333%;
    }
    .fl{
        float: left;
    }
    .fr{
        float: right;
    }
    .db{
        display: block !important;
    }
    .dn{
        display: none;
    }



    /*倒计时*/
    .djs-con-box {
        width: 100%;
        margin: 20px auto;
    }
    .djs-con-three {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content:center
    }
    .djs-con-three span:nth-child(1) {
        margin-left: 100px;
    }
    .djs-con-three span {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }
    .djs-con-three label {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #068CD1;
        margin: 0 15px;
    }

    .divText2{
        width:96%;
        margin:0 auto;
        color:#ffffff;
        background: white;
        margin-top:14px;
        border-radius:4px;
        overflow: hidden;
        padding:8px;
        box-sizing: border-box;
    }

    .divText2 h1{
        font-size:2rem;
    }

    .mzbInde{
        text-align: center;
        display:flex;
        flex-direction:column;
        border-radius:18px;
        background-size:cover;
        position:relative;
        overflow: hidden;
    }
    .mzbInde img{
        display: block;
        width:100%;
    }

    .mzbInde2{
        padding: 90px 0;
        text-align: center;
        /*background-image: url("../assets/pic/赏花季-经典路线.png") ;*/
        display:flex;
        flex-direction:column;
        border-radius:12px 12px 0 0;
        position:relative;
        background-size:cover;
    }

    .mzbInde3{
        padding: 90px 0;
        text-align: center;
        /*background-image: url("../assets/pic/花讯-牡丹阁.png") ;*/
        display:flex;
        flex-direction:column;
        border-radius:12px 12px 0 0;
        position:relative;
        background-size:cover;

    }

    .mzbInde4{
        padding: 90px 0;
        text-align: center;
        background-image: url("../assets/pic/味道-花蜜茶.png") ;
        display:flex;
        flex-direction:column;
        border-radius:12px 12px 0 0;
        position:relative;
        background-size:cover;

    }

    .TyBanner{
        background-image: linear-gradient(to right, #F66C00 , #FFAA56);
        text-align: center;
        width:70%;
        padding:6px 0;
        display: inline-block;
        margin:16px auto;
        box-sizing: border-box;
        border-radius: 10px;
        opacity: 1;
    }

    .fourDiv{
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between
    }
    .fourDiv .chilrden{
        width:48%;
        margin-top:18px;
        position:relative;
        border-radius:3px;
        overflow: hidden;
    }
    .Child-bootom{
        position:absolute;
        bottom: 0;
        background-image: linear-gradient(to right, #F66C00 , #FFAA56);
        width:100%;
        padding:8px;
        font-size:12px;
        text-align: left;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .font-header{
        display: flex;
        justify-content:space-between;
        align-items:center;
        padding:12px 0 20px 0;
    }

    .font-header h1{
        font-size:1.7rem;
        letter-spacing:4px;
        font-weight:600
    }

    .spanIcon{
        background: white;
        color:#F66C00;
        padding:4px 4px 4px 12px;
        position:absolute;
        display: inline-block;
        top:30px;
        right:0;
        border-radius:10px 0px 0px 10px;
        font-style:oblique;
        font-weight:600;
    }

    .shsdImg{
        width:100%;
        margin-bottom: 22px;
        padding:0 0 20px 0;
        border-bottom:1px solid #fafafa

    }

    .shsdImg img{
        display: block;
        width:100%;
    }


    .spanNew{
        font-size:12px;
        background-image: linear-gradient(to right, #F66C00 , #FFAA56);
        color:#ffffff;
        padding:4px 8px;
        border-radius:10px;
        margin-top:-18px;
        margin-left:10px;
        display: inline-block;

    }



</style>
