<template>
    <div class="main">
        <div class="headerVideo">
            <img src="@/assets/logo1.png">
            <img src="@/assets/logoXY.png">

        </div>
        <div class="divText">
            <h1 class="h1">
                慢直播
                <!--                <span class="sapnSize">LUOYANG CULTURE AND TOURISM INVESTMENT GTOUP CO.,LTD</span>-->
            </h1>
            <h1 class="h2">
                {{dataNew.title}}
            </h1>
        </div>

        <div>

            <video-player class="video-player vjs-custom-skin" :options="options" />

        </div>
<!--        <div class="djs">-->
<!--            <div class="djs-con-box">-->
<!--                <div class="djs-con-three">-->
<!--                    <label>{{daoTime.d}}</label>-->
<!--                    <span>天：</span>-->
<!--                    <label>{{daoTime.h}}</label>-->
<!--                    <span>时：</span>-->
<!--                    <label>{{daoTime.m}}</label>-->
<!--                    <span>分：</span>-->
<!--                    <label>{{daoTime.s}}</label>-->
<!--                    <span>秒</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="textDiv">
            <h1 class="h1">
                {{dataNew.title}}
            </h1>
            <p class="p1" v-for="(goods,index) in dataNew.ablout.paras" :key="index">
                {{goods}}
            </p>
<!--            <img :src="dataNew.img" class="imgOne">-->
            <div class="hrxin"></div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    const options = {
        playbackRates: [0.25, 0.5, 1.0, 1.25, 2.0], // 可选择的播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        sources: [{
            // type : "video/mp4",
            type: "application/x-mpegURL",
            src: require('/src/assets/mov1.mp4')
        }],
        poster:require('@/assets/mdg.png'),
        notSupportedMessage: "前方视频信号中断，请稍后再试。"
    };
    var index_djs;
    export default {
        name: "index",
        data(){
            return{
                options,
                title: "漫直播",
                videoId:'',
                localTimeStamp: new Date().getTime(),
                daoTime: '',
                poster:"@/assets/banner.png",
                mzb:'',
                dataNew:'',

            }
        },
        beforeCreate(){

        },

        mounted(){
            this.djs();
            this.dataUrl()
            this.jieshou();
            clearInterval(index_djs);
            index_djs = setInterval(this.djs, 1000)


        },
        methods:{
            jieshou(){
                console.log(this.$route.params.id);
            },
            dataUrl(){
                axios.get('/data/mzb.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.mzb=response.data.list;
                            console.log(this.mzb);
                            var videoId=this.$route.query.id;
                            console.log(this.videoId);
                            var dataNew=this.mzb.filter(
                                function(item){
                                    return item.id == videoId;
                                }
                            );
                            this.dataNew=dataNew[0];
                            console.log(this.dataNew);
                            this.options.sources[0].src = this.dataNew.hls;
                            this.options.poster=this.dataNew.img;
                        }
                    );

            },
            countDown(payEndTime, nowTime) {
                let end = new Date(payEndTime).getTime(); //1小时后  60分钟 * 60秒 * 1000  结束时间
                // console.log(end);
                // console.log(nowTime);
                let now;
                nowTime ? now = nowTime : now = new Date().getTime(); //当前时间
                let leftTime = end - now; // 结束秒数 - 现在秒数
                let d, h, m, s;
                if (leftTime > 0) {
                    d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
                    h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
                    m = Math.floor(leftTime / 1000 / 60 % 60);
                    s = Math.floor(leftTime / 1000 % 60);
                    // console.log(d + "天" + h + '小时' + m + '分钟' + s + '秒');
                    let data = {
                        d: d < 10 ? '0' + d : d,
                        h: h < 10 ? '0' + h : h,
                        m: m < 10 ? '0' + m : m,
                        s: s < 10 ? '0' + s : s
                    }
                    return data;
                }
            },
            djs() {
                var vm = this;
                // console.log(vm.localTimeStamp);
                var res = vm.countDown('2022/01/11 10:00:00', vm.localTimeStamp);
                // console.log(res);
                if (res === false) {
                    // vm.daoStatus = false;
                    clearInterval(index_djs);
                } else {
                    // vm.daoStatus = true;
                    vm.daoTime = res;
                }
                vm.localTimeStamp += 1000;
            },
            playVideo(url, text) {
                this.options.sources[0].src = url;
                this.title = text;
            }

        }
    }
</script>

<style scoped>
    .main{
        width:100vw;
        min-height:100vh;
        /*    background-color: #1C59C1;*/
        /*linear-gradient(to bottom,#558FF1,#09058E)*/
        background: linear-gradient(to bottom, #ff8a42, #F66C00);
    }

    .djs{
        margin:30px 0 30px 0;
    }

    .textDiv{
        width:96%;
        padding:0px 0 20px 0;
        margin:30px auto 0 auto;
    }

    .headerVideo{
        display:flex;
        width:60%;
        margin:0 auto;
        padding:10px 0;
    }
    .headerVideo img{
        display:block;
        width:50%;
    }

    .video{
        width:100%;
        height:auto;
    }

    .divText{
        padding:16px 0;
    }
    .sapnSize{
        -webkit-text-size-adjust: none;
        font-size:1.2vw
    }

    .p1{
        color:#ffffff;
        line-height:36px;
        text-align: left;
        padding:10px 20px 0px 20px;
        margin: 0 auto;
        text-indent:2em
    }

    .h1{
        font-size:5vw;
        color:#ffffff;
        font-weight: bold;
        text-align: center
    }

    .h2{
        font-size:9vw;
        color:#ffffff;
        font-weight: bold;
        margin:20px 0 20px 0;
        text-align: center
    }
    /* 禁用iPhone中Safari的字号自动调整 */
    html {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        /* 解决IOS默认滑动很卡的情况 */
        -webkit-overflow-scrolling : touch;
    }

    /* 禁止缩放表单 */
    input[type="submit"], input[type="reset"], input[type="button"], input {
        resize: none;
        border: none;
    }

    /* 取消链接高亮  */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 设置HTML5元素为块 */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }

    /* 图片自适应 */
    img {
        width: 100%;
        height: auto;
        width: auto\9; /* ie8 */
        display: block;
        -ms-interpolation-mode: bicubic;/*为了照顾ie图片缩放失真*/
    }

    /* 初始化 */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        margin: 0;
        padding: 0;
    }
    body {
        font: 12px/1.5 'Microsoft YaHei','宋体', Tahoma, Arial, sans-serif;
        color: #555;
        background-color: #F7F7F7;
    }
    em, i {
        font-style: normal;
    }
    ul,li{
        list-style-type: none;
    }
    strong {
        font-weight: normal;
    }
    .clearfix:after {
        content: "";
        display: block;
        visibility: hidden;
        height: 0;
        clear: both;
    }
    .clearfix {
        zoom: 1;
    }
    a {
        text-decoration: none;
        color: #969696;
        font-family: 'Microsoft YaHei', Tahoma, Arial, sans-serif;
    }
    a:hover {
        text-decoration: none;
    }
    ul, ol {
        list-style: none;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-family: 'Microsoft YaHei';
    }
    img {
        border: none;
    }
    input{
        font-family: 'Microsoft YaHei';
    }
    /*单行溢出*/
    .one-txt-cut{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /*多行溢出 手机端使用*/
    .txt-cut{
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
    }
    /* 移动端点击a链接出现蓝色背景问题解决 */
    a:link,a:active,a:visited,a:hover {
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    .w50{
        width: 50%;
    }
    .w25{
        width: 25%;
    }
    .w20{
        width: 20%;
    }
    .w33{
        width: 33.333333%;
    }
    .fl{
        float: left;
    }
    .fr{
        float: right;
    }
    .db{
        display: block !important;
    }
    .dn{
        display: none;
    }



    /*倒计时*/
    .djs-con-box {
        width: 100%;
        margin: 20px auto;
    }
    .djs-con-three {
        display: flex;
        align-items: center;
        justify-content:center
    }
    .djs-con-three span:nth-child(1) {
        margin-left: 100px;
    }
    .djs-con-three span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }
    .djs-con-three label {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 800;
        color: #0C0A91;
        margin: 0 6px;

    }

    .imgOne{
        display: block;
        width:94%;
        margin:16px auto 16px auto;
    }

    /*.hrxin{*/
    /*    width:94%;*/
    /*    height:1px;*/
    /*    background-color: #080758;*/
    /*    margin:16px auto 16px auto;*/
    /*}*/

</style>
