import Vue from 'vue'
import Router from 'vue-router'
import Video from '@/components/Video'
import VideoMain from '@/components/VideoMain'
import Huahui from '@/components/huahui'


Vue.use(Router);

export default new Router({
    routes: [
        {
          path: '/',
          name: 'Huahui',
          component: Huahui,
            meta:{
              keepAlive:true
            }
        },

        {
            path: '/Video',
            name: 'Video',
            component: Video,
        },

        {
            path: '/VideoMain',
            name: 'VideoMain',
            component: VideoMain,
            meta:{
                keepAlive:true
            }
        },



        // {
        //   path: '/',
        //   name: 'login',
        //   component: login
        // }
        // {
        //   path: '/',
        //   name: 'subscribe',
        //   component: subscribe
        // },
    ]
})
