<template>
    <div class="main">
<!--        <div class="headerVideo">-->
<!--            <img src="@/assets/logo1.png">-->
<!--            <img src="@/assets/logoXY.png">-->

<!--        </div>-->


<!--        <div class="divText" style="       background-image: linear-gradient(to right, #f6eecd , #ffffff);">-->
<!--            <h1 class="h1" style="margin-top:16px;">-->
<!--                景区慢直播-->
<!--                &lt;!&ndash;                <span class="sapnSize">LUOYANG CULTURE AND TOURISM INVESTMENT GTOUP CO.,LTD</span>&ndash;&gt;-->
<!--            </h1>-->
<!--        </div>-->

        <div>
            <video-player class="video-player vjs-custom-skin" :options="options" />
        </div>
        <div class="textDiv" style="margin:0;">
            <div>
                <h1 style="font-size:24px; font-weight:bold;">此刻洛阳<span class="spanNew">直播中</span></h1>
                <p style="font-size:12px; margin-top:7px; color:#878787;">精挑细选此刻美景，一部手机看尽最美洛阳</p>
            </div>
        </div>
        <div class="textDiv" style="background: white; margin-top:14px; border-radius: 3px">
            <div style="color:#000000" class="font-header">
                <h1 style="font-weight: bold">直播热榜</h1>
                <p class="TyBanner" style="width:40%; margin:0; color:#878787; text-align: right"></p>
            </div>


            <div class="fourDiv">

<!--                列表遍历对象-->
                <div class="chilrden" v-for="(goods,index) in mzb" :key="index" @click="goHref('Video',goods.id)">
                      <img :src="goods.img">
                      <div class="bootom">
                        {{goods.title}}
                      </div>
                </div>


            </div>


        </div>
<!--        <div class="djs">-->
<!--            <div class="djs-con-box">-->
<!--                <div class="djs-con-three">-->
<!--                    <label>{{daoTime.d}}</label>-->
<!--                    <span>天：</span>-->
<!--                    <label>{{daoTime.h}}</label>-->
<!--                    <span>时：</span>-->
<!--                    <label>{{daoTime.m}}</label>-->
<!--                    <span>分：</span>-->
<!--                    <label>{{daoTime.s}}</label>-->
<!--                    <span>秒</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->


        <div class="footer textDiv" style="padding-bottom: 18px;  padding-top:6px;">
            <div>
<!--                <p>{{mzbTitle.title}}</p>-->
<!--                <p>{{mzbTitle.introduce}}</p>-->
<!--                <div class="footerImg">-->
<!--                    <img src="@/assets/logo3.png" style="width:8%">-->
<!--                    <p>洛阳文化旅游投资集团有限公司  </p>-->

<!--                </div>-->
<!--                <div class="footerImg">-->
<!--                    <img src="@/assets/logo4.png" style="width:4%">-->
<!--                    <p>洛阳文化广电和旅游局</p>-->

<!--                </div>-->

                <div class="footerImg" style="justify-content:center;">
<!--                    <img src="@/assets/logo3.png" style="width:6%">-->
                    <p>洛阳文化旅游投资集团有限公司</p>
<!--                    <img src="@/assets/logo4.png" style="width:4%; margin-left: 6px;">-->
                    <p style="margin-left: 10px;">洛阳市文化广电和旅游局</p>

                </div>
                <div class="hrDiv">

                </div>


<!--                <p ><span style="font-weight: 600">支持单位</span><br><br>中国联通洛阳分公司，中国移动通信集团河南有限公司洛阳分公司</p>-->
                <p style="margin-bottom: 10px; font-size:14px;">- 支持单位 -</p>

                <div class="footerImg">
                    <div class="footerImg divText">
                        <img src="@/assets/logo5.png" class="divImg">
                        <p class="divP">中国联通<br>洛阳分公司</p>
                    </div>

                    <div class="footerImg divText">
                        <img src="@/assets/logo6.png" class="divImg">
                        <p class="divP">中国移动<br>洛阳分公司</p>
                    </div>

                    <div class="footerImg divText">
                        <img src="@/assets/logo7.png" class="divImg">
                        <p class="divP">中国电信<br>洛阳分公司</p>
                    </div>

                </div>

<!--                                    <div class="footerImg">-->
<!--                                        <img src="@/assets/logo5.png" style="width:6%">-->
<!--                                        <p>中国联通洛阳分公司  </p>-->

<!--                                    </div>-->
<!--                                    <div class="footerImg">-->
<!--                                        <img src="@/assets/logo6.png" style="width:6%">-->
<!--                                        <p>中国移动洛阳分公司</p>-->
<!--                                    </div>-->
<!--                <div class="footerImg">-->
<!--                    <img src="@/assets/logo6.png" style="width:6%">-->
<!--                    <p>中国电信洛阳分公司</p>-->
<!--                </div>-->

            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    const options = {
        playbackRates: [0.25, 0.5, 1.0, 1.25, 2.0], // 可选择的播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",

        sources: [{
            // type : "video/mp4",
            type: "application/x-mpegURL",
            src: require('/src/assets/mov1.mp4')
        }],
        poster:require('@/assets/mdg.png'),
        notSupportedMessage: "前方视频信号中断，请稍后再试。"
    };
    var index_djs;
    export default {
        name: "index",
        data(){
            return{
                options,
                title: "洛阳文化旅游投资集团有限公司",
                localTimeStamp: new Date().getTime(),
                daoTime: '',
                poster:"@/assets/banner2.png",
                mzb:'',
                mzbTitle:"",

            }
        },

        mounted(){
            this.djs();
            clearInterval(index_djs);
            index_djs = setInterval(this.djs, 1000)
            this.options.sources[0].src = "http://yungj.lytrip.com.cn:10086/hls/1/playlist.m3u8";
            this.dataUrl();
        },
        methods:{

            goHref(title,id){
                this.$router.push({
                    name:title,
                    query:{
                        id:id
                    }
                })
            },

            dataUrl(){
                axios.get('/data/mzb.json?ver='+Math.random()) //vue
                    .then(
                        response=>{
                            console.log(response.data);
                            this.mzb=response.data.list;
                            console.log(this.mzb);
                            this.options.sources[0].src=response.data.top.hls;
                            this.options.poster=response.data.top.img;
                            var str=JSON.stringify(response.data);
                            var strJSON=JSON.parse(str);

                            this.mzbTitle=strJSON.footer;
                            console.log(this.mzbTitle)

                        }
                    );

            },
            countDown(payEndTime, nowTime) {
                let end = new Date(payEndTime).getTime(); //1小时后  60分钟 * 60秒 * 1000  结束时间
                // console.log(end);
                // console.log(nowTime);
                let now;
                nowTime ? now = nowTime : now = new Date().getTime(); //当前时间
                let leftTime = end - now; // 结束秒数 - 现在秒数
                let d, h, m, s;
                if (leftTime > 0) {
                    d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
                    h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
                    m = Math.floor(leftTime / 1000 / 60 % 60);
                    s = Math.floor(leftTime / 1000 % 60);
                    // console.log(d + "天" + h + '小时' + m + '分钟' + s + '秒');
                    let data = {
                        d: d < 10 ? '0' + d : d,
                        h: h < 10 ? '0' + h : h,
                        m: m < 10 ? '0' + m : m,
                        s: s < 10 ? '0' + s : s
                    }
                    return data;
                }
            },
            djs() {
                var vm = this;
                // console.log(vm.localTimeStamp);
                var res = vm.countDown('2022/01/11 10:00:00', vm.localTimeStamp);
                // console.log(res);
                if (res === false) {
                    // vm.daoStatus = false;
                    clearInterval(index_djs);
                } else {
                    // vm.daoStatus = true;
                    vm.daoTime = res;
                }
                vm.localTimeStamp += 1000;
            },
            playVideo(url, text) {
                this.options.sources[0].src = url;
                this.title = text;
            }

        }
    }
</script>

<style scoped>
    .main{
        width:100vw;
        min-height:100vh;
            background-color: #f9fafb;
        /*linear-gradient(to bottom,#558FF1,#09058E)*/
    }

    .djs{
        margin:30px 0 30px 0;
    }

    .spanNew{
        font-size:12px;
        background-image: linear-gradient(to right, #F66C00 , #FFAA56);
        color:#ffffff;
        padding:4px 8px;
        border-radius:10px;
        margin-top:-18px;
        margin-left:10px;
        display: inline-block;

    }

    .textDiv{
        width:96%;
        padding:14px 8px 8px 8px;
        margin:22px auto 0 auto;
        box-sizing: border-box;

    }

    .headerVideo{
        display:flex;
        width:60%;
        margin:0 auto;
        padding:10px 0;
    }
    .headerVideo img{
        display:block;
        width:50%;
    }

    .video{
        width:100%;
        height:auto;
    }

    .divText{
        padding:16px 0;
    }
    .sapnSize{
        -webkit-text-size-adjust: none;
        font-size:1.2vw
    }

    .p1{
        line-height:36px;
        text-align: left;
        padding:30px 20px 15px 20px;
        margin: 0 auto;
        text-indent:2em
    }

    .h1{
        font-size:5vw;

        font-weight: bold;
        text-align: center
    }

    .h2{
        font-size:9vw;

        font-weight: bold;
        margin:20px 0 20px 0;
        text-align: center
    }
    /* 禁用iPhone中Safari的字号自动调整 */
    html {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        /* 解决IOS默认滑动很卡的情况 */
        -webkit-overflow-scrolling : touch;
    }

    /* 禁止缩放表单 */
    input[type="submit"], input[type="reset"], input[type="button"], input {
        resize: none;
        border: none;
    }

    /* 取消链接高亮  */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 设置HTML5元素为块 */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }

    /* 图片自适应 */
    img {
        width: 100%;
        height: auto;
        width: auto\9; /* ie8 */
        display: block;
        -ms-interpolation-mode: bicubic;/*为了照顾ie图片缩放失真*/
    }

    /* 初始化 */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        margin: 0;
        padding: 0;
    }
    body {
        font: 12px/1.5 'Microsoft YaHei','宋体', Tahoma, Arial, sans-serif;
        color: #4b4b4b;
        background-color: #F7F7F7;
    }
    em, i {
        font-style: normal;
    }
    ul,li{
        list-style-type: none;
    }
    strong {
        font-weight: normal;
    }
    .clearfix:after {
        content: "";
        display: block;
        visibility: hidden;
        height: 0;
        clear: both;
    }
    .clearfix {
        zoom: 1;
    }
    a {
        text-decoration: none;
        color: #969696;
        font-family: 'Microsoft YaHei', Tahoma, Arial, sans-serif;
    }
    a:hover {
        text-decoration: none;
    }
    ul, ol {
        list-style: none;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-family: 'Microsoft YaHei';
    }
    img {
        border: none;
    }
    input{
        font-family: 'Microsoft YaHei';
    }
    /*单行溢出*/
    .one-txt-cut{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /*多行溢出 手机端使用*/
    .txt-cut{
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
    }
    /* 移动端点击a链接出现蓝色背景问题解决 */
    a:link,a:active,a:visited,a:hover {
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    .w50{
        width: 50%;
    }
    .w25{
        width: 25%;
    }
    .w20{
        width: 20%;
    }
    .w33{
        width: 33.333333%;
    }
    .fl{
        float: left;
    }
    .fr{
        float: right;
    }
    .db{
        display: block !important;
    }
    .dn{
        display: none;
    }



    /*倒计时*/
    .djs-con-box {
        width: 100%;
        margin: 20px auto;
    }
    .djs-con-three {
        display: flex;
        align-items: center;
        justify-content:center
    }
    .djs-con-three span:nth-child(1) {
        margin-left: 100px;
    }
    .djs-con-three span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }
    .djs-con-three label {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 800;
        color: #0C0A91;
        margin: 0 6px;

    }

    .imgOne{
        display: block;
        width:94%;
        margin:16px auto 16px auto;
    }

    .font-header{
        display: flex;
        justify-content:space-between;
        align-items:center;
    }

    .TyBanner{
        text-align: center;
        width:70%;
        padding:6px 0;
        display: inline-block;
        margin:16px auto;
        box-sizing: border-box;
        border-radius: 10px;
        opacity: 1;
    }

    .fourDiv{
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between
    }

    .fourDiv .chilrden{
        width:48.5%;
        margin-top:12px;
        position:relative;
        border-radius:3px;
        overflow: hidden;
    }

    .fourDiv .chilrden .bootom{
        position:absolute;
        bottom: 0;
        background-image: linear-gradient(to right, #F66C00 , #FFAA56);
        width:100%;
        padding:8px;
        font-size:12px;
        text-align: left;
        box-sizing: border-box;
        color:#ffffff;
    }

    .footer{
        font-size:13px;
        text-align: center;
        color: #515151;
        font-weight: 600;
    }

    .footerImg{
        display: flex;
        justify-content:space-between;
        align-items:center;
    }

    .hrDiv{
        height:1px;
        width:100%;
        background: #eeeeee;
        margin:18px auto 12px 0;

    }

    .divText{
        box-sizing: border-box;
        padding: 10px;
        background: #ffffff;
        width:32.4%;
        border-radius:3px;

    }

    .divP{
        text-align: left;
        line-height:17px;
    }
    .divImg{
        width:35%;

    }
    /*.hrxin{*/
    /*    width:94%;*/
    /*    height:1px;*/
    /*    background-color: #080758;*/
    /*    margin:16px auto 16px auto;*/
    /*}*/

</style>
