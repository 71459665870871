<template>
  <div id="app">
<!--    <Video/>-->
          <router-view/>
  </div>
</template>

<script>
// import Video from "./components/Video.vue";
// import VideoMain from "./components/VideoMain.vue";

export default {
  name: "App",
  components: {
    // Video,
      // VideoMain
  }
};
</script>

<style>
#app {
}
</style>
